<template>
  <form ref="funnelForm">
    <CRow>
      <CCol sm="6">
        <CInput
            label="Name"
            name="name"
            v-model="name"
            description="Bitte geben Sie einen Namen für diesen E-Mail-Newsletter ein."
            required
        />
      </CCol>
      <CCol sm="6">
        <CInput
            label="E-Mail: Betreff"
            name="email"
            v-model="subject"
            description="Betreff der E-Mail."
            required
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="4">
        <DateTimePicker
            label="Zeitpunkt des Versendes"
            :horizontal="false"
            v-model="sendAt"
        />
      </CCol>
      <CCol sm="4">
        <NewsletterGroupSelector
            v-model="groups"
            description="Hier können Sie Ihre Zielgruppen auswählen, die diesen einmaligen E-Mail Newsletter erhalten sollen.
                Mit Zielgruppen können Sie gezielte Newsletter verfassen und an die jeweiligen Zielgruppe senden.
                Wenn keine Zielgruppe ausgewählt ist, bekommen Abonnenten ohne Zielgruppe alle Newsletter die Sie verfassen."
        />
      </CCol>
      <CCol sm="4">
        <label for="active">Versand des E-Mail-Newsletter aktivieren?</label>
        <CInputCheckbox
            label="Aktiv?"
            id="active"
            name="active"
            value="Aktiv"
            :custom="true"
            :checked.sync="active"
            :inline="false"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <UnlayerEmailEditor
            ref="unlayerEditor"
            label="E-Mail: Inhalt"
            :value="message"
            displayMode="email"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CButton v-if="!processing" @click="clickHandler" color="success">
          {{ btnText }}
        </CButton>
        <CButton v-else color="primary">
          Processing...
        </CButton>
        <CButton v-if="enableDelete" @click="deleteHandler" color="danger" style="margin-left: 4px">
          Löschen
        </CButton>
        <CButton v-if="enableDelete" @click="sendTestEmailHandler" color="warning" style="margin-left: 4px">
          Test E-Mail versenden
        </CButton>
      </CCol>
    </CRow>
  </form>
</template>

<script>
import WysiwygEditor from '@/components/WysiwygEditor'
import Checkbox from '@/components/Checkbox'
import axios from 'axios'
import moment from "moment";
import DateTimePicker from "@/components/DateTimePicker";
import NewsletterGroupSelector from "@/components/NewsletterGroupSelector";
import UnlayerEmailEditor from "@/components/UnlayerEditorWrapper";
import commons from "@/commons";

export default {
  name: 'NewsletterArticleForm',
  components: { UnlayerEmailEditor, DateTimePicker, WysiwygEditor, Checkbox, NewsletterGroupSelector },
  props: {
    passedData: {
      type: Object,
      default: () => ( { id: 0 } )
    },
    btnText: {
      type: String,
      required: true
    },
    enableDelete: Boolean,
    processing: Boolean
  },
  data() {
    return {
      id: this.passedData.id || 0,
      realmId: (this.passedData.realm || {}).id || this.passedData.realmId || 1,
      name: this.passedData.name || '',
      subject: this.passedData.subject || '',
      message: this.passedData.message || '',
      messageType: this.passedData.messageType || 'UNLAYER_JSON',
      groups: this.passedData.groups || [],
      active: typeof this.passedData.active == 'boolean' ? this.passedData.active : true,
      sendAt: this.passedData.sendAt || moment().add(1, 'days').toISOString()
    }
  },
  methods: {
    validateBeforeSubmit() {
      return new Promise((resolve, reject) => {
        if (this.$refs.funnelForm.checkValidity()) {
          this.$refs.unlayerEditor.saveDesign().then(design => {
            resolve({ ...this.$data, message: JSON.stringify(design) })
          }).catch(err => reject(err))
        } else {
          this.$refs.funnelForm.reportValidity()
          reject()
        }
      })
    },
    async clickHandler() {
      const data = await this.validateBeforeSubmit()
      this.$emit('submit', data)
    },
    deleteHandler() {
      this.$modal.showModal(
          'delete',
          null,
          [`<b>NewsletterArticle</b> (${this.passedData.name})`],
          async () => {
            try {
              let resp = await axios.delete(`/newsletter/article/${this.id}`)
              this.$toaster.makeToast('Erfolgreich!', 'Der Artikel wurde erfolgreich gelöscht.')
              this.$router.push({name: 'NewsletterArticles', params: { saved: '1' }})
            } catch (e) {
              this.$toaster.makeToast('Fehler!', 'Der Artikel konnte nicht gelöscht werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.')
            }
          }
      )
    },
    async sendTestEmailHandler() {
      const data = await this.validateBeforeSubmit()
      this.$modal.showModal(
          'default',
          'Diesen Artikel testen?',
          [
            `Hiermit wird zum Test dieser Newsletter Artikel an die E-Mail des aktuellen Users (${commons.getCurrentUser().email}) gesendet.`,
            `<b>Achtung:</b> Dieser Newsletter Artikel wird vorher gespeichert!`
          ],
          () => {
            this.$emit('sendTestEmail', data)
          },
          () => {}
      )
    }
  }
}
</script>
